import {
  AudioNames,
  LungeStates,
  PlayerAnimationsNames,
  PlayerStates,
  Sides
} from '@/app/types'
import type { Athlete } from './Athlete'
import {
  audioManager,
  CallbackAnimationTypes,
  modes,
  playersManager
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { gameSettingsState } from '@powerplay/core-minigames-ui-ssm'
import { paddlingGroups } from '@/app/config'
import { startMessageState } from '@/stores'
import { player } from './player'
import { worldEnv } from '../env/WorldEnv'

/**
 * Dedikovany manazer animacii pre hraca/superov.
 */
export class AthleteAnimationManager {

  /** ci sme uz nastavili podla stavov veci */
  private statesActive = {
    [PlayerStates.ready]: false,
    [PlayerStates.run]: false,
    [PlayerStates.end]: false,
    [PlayerStates.lunge]: false,
    [PlayerStates.finish]: false,
  }

  /** aktualna animacia - TODO rozsir o vsetky animacie mimo konecnej */
  public actualAnimation?: PlayerAnimationsNames

  /** ci sme nastavili konecnu animaciu */
  public isEndEmotionSet = false

  /** Tween do run */
  private tweenToRun?: gsap.core.Tween

  /** buduca mozna animacia kvoli crossfadeu */
  private possibleNextAnimation?: PlayerAnimationsNames

  /** ake animacie zvolit pri stranach */
  public animationSide = Sides.RIGHT

  /** ci je aktivna animacia toEmotion pri spatnom lunge-y */
  private toEmotionActive = false

  /** animacia emocie */
  private emotionAnimation = PlayerAnimationsNames.balance1

  /**
   * Konstruktor
   * @param athlete - Atlet
   */
  public constructor(private athlete: Athlete) {}

  /**
   * Zmena rychlosti animacii
   * @param speed - Nova rychlost animacii
   */
  private changeAnimationSpeed(speed: number) {

    this.athlete.animationsManager.setSpeed(speed)

  }

  /**
   * Reset rychlosti animacii
   */
  private resetAnimationSpeed() {

    this.athlete.animationsManager.resetSpeed()

  }

  /**
   * Skipnutie rozbehu, hlavne kvoli false start
   */
  public skipStartRun(): void {

    this.tweenToRun?.progress(1)

  }

  /**
   * Riesenie veci pre ground stav
   * @returns True, ak ide o dany stav
   */
  private isReadyState(): boolean {

    const isReady = this.athlete.isState(PlayerStates.ready)

    if (!this.statesActive[PlayerStates.ready] && isReady) {

      // podla toho ci je toto hrac alebo super, nastavime lavaka ci pravaka
      const rightPlayer = this.athlete.playable && !gameSettingsState().isLeft
      const rightOpponent = !this.athlete.playable && Math.random() > 0.5
      this.animationSide = rightPlayer || rightOpponent ? Sides.RIGHT : Sides.LEFT
      startMessageState().showStartArrow = true
      this.athlete.velocityManager.activeSide = gameSettingsState().isLeft ? Sides.RIGHT : Sides.LEFT
      this.statesActive[PlayerStates.ready] = true
      const animation = this.animationSide === Sides.RIGHT ?
        PlayerAnimationsNames.readyL :
        PlayerAnimationsNames.readyR
      this.athlete.animationsManager.changeTo(animation)

    }

    return isReady

  }

  /**
   * Riesenie veci pre run stav
   * @returns True, ak ide o dany stav
   */
  private isRunState(): boolean {

    const isRun = this.athlete.isState(PlayerStates.run)
    const leftSide = disciplinePhasesManager.phaseStart.wasFalseStart ? Sides.RIGHT : Sides.LEFT

    const animation = this.animationSide === leftSide ?
      PlayerAnimationsNames.paddlingL :
      PlayerAnimationsNames.paddlingR

    if (isRun) {

      // pokial to iba teraz zacalo, tak nastavime prve veci
      if (!this.statesActive[PlayerStates.run]) {

        this.statesActive[PlayerStates.run] = true

        if (disciplinePhasesManager.phaseStart.wasFalseStart) {

          this.athlete.animationsManager.changeTo(animation)
          this.athlete.animationsManager.manualyUpdateTimeByPercent(animation, 50)

        } else {

          this.athlete.animationsManager.changeToPaused(animation)
          this.athlete.animationsManager.manualyUpdateTimeByPercent(animation, 0)

        }

      }

      // toto robime stale
      if (!disciplinePhasesManager.phaseStart.wasFalseStart) {

        let frames = this.athlete.velocityManager.intervalFrames +
          (
            this.athlete.velocityManager.activeSide === this.animationSide ?
              this.athlete.velocityManager.paddleFrequency :
              0
          )
        frames += Math.ceil(this.athlete.velocityManager.paddleFrequency / 3)
        frames %= this.athlete.velocityManager.paddleFrequency * 2
        this.athlete.animationsManager.manualyUpdateTimeByPercent(
          animation,
          frames / (this.athlete.velocityManager.paddleFrequency * 2) * 100
        )

        if (this.athlete.playable) {

          const possibleFrames = [1, 1 + this.athlete.velocityManager.paddleFrequency]
          if (possibleFrames.includes(frames)) {

            const audioNamePossibleSuffixes = paddlingGroups[`freq${this.athlete.velocityManager.paddleFrequency}`]
            const index = Math.floor(Math.random() * audioNamePossibleSuffixes.length)
            const audioNameSuffix = audioNamePossibleSuffixes[index]
            if (audioNameSuffix) {

              const audioName = AudioNames.paddlingPrefix + audioNameSuffix
              audioManager.stopAudioByName(audioName)
              audioManager.play(audioName)

            }

          }

          if (this.athlete.velocityManager.intervalFrames === 1) {

            const coef = this.athlete.velocityManager.activeSide === Sides.LEFT ? -1 : 1
            const position = player.getPosition().clone()
            position.x += (0.75 * coef)
            position.z -= 0.2
            worldEnv.showSplashes(position)

          }

        }

      }

    }

    return isRun

  }

  /**
   * Zistenie animacie pre emociu v cieli
   * @returns Animacia
   */
  private getEmotionAnimation(): PlayerAnimationsNames {

    const position = playersManager.getPlayerActualPosition(this.athlete.uuid)
    const top3 = position <= 3

    if ((this.athlete.playable && position >= 6) || (modes.isEventBossFight() && position === 2)) {

      return Math.random() < 0.5 ? PlayerAnimationsNames.sad1 : PlayerAnimationsNames.sad2

    }
    if (top3) return this.getEndHappyAnimation()

    return this.animationSide === Sides.LEFT ? PlayerAnimationsNames.balance1 : PlayerAnimationsNames.balance2

  }

  /**
   * Zistenie konkretnej animacie end happy
   * @returns Animacia
   */
  private getEndHappyAnimation(): PlayerAnimationsNames {

    const random = Math.ceil(Math.random() * 4)
    let animation = PlayerAnimationsNames.happy1
    if (random === 1) animation = PlayerAnimationsNames.happy2
    if (random === 2) animation = PlayerAnimationsNames.happy3
    if (random === 4) animation = PlayerAnimationsNames.happy4

    return animation

  }

  /**
   * Riesenie veci pre lunge stav
   * @returns True, ak ide o dany stav
   */
  private isLungeState(): boolean {

    const isLunge = this.athlete.isState(PlayerStates.lunge)

    if (isLunge) {

      let animation = this.animationSide === Sides.LEFT ?
        PlayerAnimationsNames.finishL :
        PlayerAnimationsNames.finishR

      if (!this.statesActive[PlayerStates.lunge]) {

        this.athlete.animationsManager.changeToPaused(animation)
        this.statesActive[PlayerStates.lunge] = true
        this.statesActive[PlayerStates.run] = false

      }


      if (this.athlete.lungeState === LungeStates.backward) {

        animation = this.animationSide === Sides.LEFT ?
          PlayerAnimationsNames.toEmotionL :
          PlayerAnimationsNames.toEmotionR

        // ak sme este nemali backward cast, tak zmenime animaciu na to emotion
        if (!this.toEmotionActive) {

          this.toEmotionActive = true
          this.athlete.animationsManager.changeToPaused(animation)

        }

      }

      let percent = this.athlete.lungeActualFrame / this.athlete.getLungeMaxFrames()
      if (this.athlete.lungeState === LungeStates.stay) percent = 1
      if (this.athlete.lungeState === LungeStates.none) percent = -1

      if (percent >= 0) this.athlete.animationsManager.manualyUpdateTimeByPercent(animation, percent * 100)

    }

    return isLunge

  }

  /**
   * Riesenie veci pre koniec trate stav
   * @returns True, ak ide o dany stav
   */
  private isFinishState(): boolean {

    const isFinish = this.athlete.isState(PlayerStates.finish)

    if (!this.statesActive[PlayerStates.finish] && isFinish) {

      this.statesActive[PlayerStates.finish] = true

      const animation = this.animationSide === Sides.LEFT ?
        PlayerAnimationsNames.balance1 :
        PlayerAnimationsNames.balance2
      this.athlete.animationsManager.crossfadeTo(animation, 0.2, true, false)

    }

    return isFinish

  }

  /**
   * Riesenie veci pre end stav
   * @returns True, ak ide o dany stav
   */
  private isEndState(): boolean {

    const isEnd = this.athlete.isState(PlayerStates.end)

    if (!this.statesActive[PlayerStates.end] && isEnd) {

      this.statesActive[PlayerStates.end] = true

      const animationLunge = this.animationSide === Sides.LEFT ?
        PlayerAnimationsNames.finishL :
        PlayerAnimationsNames.finishR
      this.athlete.animationsManager.setWeight(animationLunge, 0)

      const animation = this.getEmotionAnimation()
      this.emotionAnimation = animation

      this.athlete.animationsManager.addAnimationCallback(
        animation,
        CallbackAnimationTypes.loop,
        () => {

          this.athlete.setState(PlayerStates.finish)
          this.athlete.animationsManager.removeAnimationCallback(
            animation,
            CallbackAnimationTypes.loop
          )
          // this.athlete.animationsManager.setWeight(animation, 0)

        }
      )
      this.athlete.animationsManager.crossfadeTo(animation, 0.2, true, false)

    }

    return isEnd

  }

  /**
   * Samotna logika
   */
  private animationLogic(): void {

    this.isLungeState()
    this.isFinishState()
    this.isRunState()
    this.isReadyState()
    this.isEndState()

  }

  /**
   * Update metoda volana v move metode velocity manazera
   */
  public update(): void {

    this.animationLogic()

  }

  /**
   * Zresetovanie veci
   */
  public reset(): void {

    this.actualAnimation = undefined
    this.isEndEmotionSet = false
    this.toEmotionActive = false
    this.statesActive = {
      [PlayerStates.ready]: false,
      [PlayerStates.run]: false,
      [PlayerStates.end]: false,
      [PlayerStates.lunge]: false,
      [PlayerStates.finish]: false,
    }
    this.athlete.animationsManager.removeAnimationCallback(
      this.emotionAnimation,
      CallbackAnimationTypes.loop
    )

  }

}
