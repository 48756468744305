import {
  gsap,
  CameraStates,
  corePhasesManager,
  modes,
  cameraManager,
  audioManager,
  timeManager,
  TimesTypes,
  tutorialManager
} from '@powerplay/core-minigames'
import {
  type DisciplinePhaseManager,
  type DisplayMessage,
  TutorialObjectiveIds,
  AudioNames,
  PlayerStates,
  Tasks,
  TutorialEventType,
  Sides,
  AudioGroups
} from '../../types'
import { player } from '../../entities/athlete/player'
import {
  gameConfig,
  startConfig,
  trainingConfig
} from '../../config'
import { startPhaseStateManager } from './StartPhaseStateManager'
import { tutorialObjectives } from '@/app/modes/tutorial/TutorialObjectives'
import { disciplinePhasesManager } from '../DisciplinePhasesManager'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { tutorialUIChange } from '@/app/modes/tutorial/TutorialUIChange'
import { opponentsManager } from '@/app/entities/athlete/opponent/OpponentsManager'
import { worldEnv } from '@/app/entities/env/WorldEnv'
import { trainingTasks } from '@/app/modes/training'
import {
  actionButtonState,
  blackOverlayState,
  gamePhaseState,
  inputsState,
  lapPositionState,
  startDebugPhaseState,
  startMessageState,
  startPhaseState,
  textMessageState,
  uiState
} from '@/stores'
import { gameSettingsState } from '@powerplay/core-minigames-ui-ssm'
import { audioHelper } from '@/app/audioHelper/AudioHelper'

/**
 * Trieda pre startovaciu fazu
 */
export class StartPhaseManager implements DisciplinePhaseManager {

  /** ci sa deje nieco skipnutelne */
  private skippable = true

  /** ci sa deje nieco skipnutelne v druhej faze */
  private skippableSecond = true

  /** Ci vieme skipnut false start */
  private skippableFalseStart = false

  /** ci uz je mozne odstartovat */
  public startable = false

  /** ci uz zobrazit ui player-info-avatar */
  private showName = false

  /** ci bolo skipnute */
  private skipped = false

  /** ci bolo skipnute v druhej faze */
  private skippedSecond = false

  /** ci faza skoncila */
  public ended = false

  /** hodnota na ktorej stlacil */
  public clickedPower = 0

  /** Pocet frameov od zaciatku fazy */
  private framesInPhase = 0

  /** tween na skrytie odrazovej hlasky */
  public startingMessageTween?: gsap.core.Tween

  /** tween na ukoncenie false startu */
  private falseStartFinishTween?: gsap.core.Tween

  /** callback na zavolanie po skonceni fazy */
  private callbackEnd: () => unknown

  /** ako dlho bude zobrazena hlaska na odraze */
  private STARTING_MESSAGE_DURATION = 3

  /** Premenna pre kameru */
  private cameraInPostIntroState = false

  /** Tween pre startovacie veci */
  private startTween?: gsap.core.Tween

  /** zobrazenie bielej ziary */
  public showShine = false

  /** Ci sa uz odstartovalo alebo nie */
  private started = false

  /** ci bol false start */
  public wasFalseStart = false

  /** prvy vystartoval */
  private firstStarted = false

  /** aka strana bola stlacena pri starte */
  private startSide = Sides.LEFT

  /** Ci bola spravna strana na starte */
  private correctStartSide = false


  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase(): void {

    this.storeState()
    startPhaseStateManager.disableInputs()
    startPhaseStateManager.enableStartInputs()

  }

  /**
   * Zacatie fazy
   */
  public startPhase(): void {

    worldEnv.changeWaterFresnel()

    if (this.ended) {

      this.reset()

      if (modes.isTutorial()) {

        // musime doplnit vsade hracovu poziciu pre kamerove obejkty a potom nastavit tweeny
        player.updateCameraConfigOnStart(CameraStates.disciplineIntro)

        if (gameConfig.cameraConfig.enabled) {

          player.setGameCameraSettings()

        }
        this.afterCameraDisciplineIntro()
        this.removeBlackOverlay()
        return

      }

      this.secondCameraDisciplineIntro()
      this.removeBlackOverlay()
      return

    }

    // audioManager.stopAudioByName(AudioNames.audienceNoise)
    if (!modes.isTutorial()) audioManager.play(AudioNames.audienceHyped2)
    audioHelper.stopAudioWithDelay(AudioNames.audienceNoise)
    // Zobrazit meno hraca v UI
    this.showName = !modes.isTutorial()
    this.setCameraForDisciplineIntro()

  }

  /**
   * Nastavenie kamery pre intro
   */
  private setCameraForDisciplineIntro(): void {

    // musime doplnit vsade hracovu poziciu pre kamerove obejkty a potom nastavit tweeny
    player.updateCameraConfigOnStart(CameraStates.disciplineIntro)

    if (gameConfig.cameraConfig.enabled) {

      player.setGameCameraSettings()

    }
    if (modes.isTutorial()) {

      this.afterCameraDisciplineIntro()
      tutorialFlow.init()
      tutorialUIChange.init()
      return

    }

    if (modes.isTrainingMode()) return
    cameraManager.setState(CameraStates.disciplineIntro)
    cameraManager.playTween(false, this.secondCameraDisciplineIntro)

  }

  /** pomocna metoda pre animacie */
  public getCameraInPostIntroState(): boolean {

    return this.cameraInPostIntroState

  }

  /**
   * Druhe disciplinove kamerove intro
   */
  public secondCameraDisciplineIntro = (): void => {

    this.skippable = false
    // Zrusit zobrazenie mena hraca v UI
    this.showName = false

    if (modes.isTrainingMode() || this.wasFalseStart) {

      this.wasFalseStart = false
      this.afterCameraDisciplineIntro()
      return

    }
    // musime dat chvilku pauzu, aby sa dalo skipovat 2x po sebe
    gsap.to({}, {
      duration: 0.1,
      onComplete: () => {

        // musime zmenit hracovu animaciu naspat
        player.setState(PlayerStates.prepare2)

        cameraManager.setState(CameraStates.disciplineIntroSecond)
        cameraManager.playTween(false, this.afterCameraDisciplineIntro)

        this.skippableSecond = true

      }
    })

  }

  /**
   * Spravenie veci po konci disciplinoveho intra
   */
  private afterCameraDisciplineIntro = (): void => {

    this.skippable = false
    this.skippableSecond = false

    // nastavime stav hracom, aby isli aj animacie
    player.setState(PlayerStates.ready)
    opponentsManager.setStateToAll(PlayerStates.ready)

    // nastavime spravnu poziciu
    player.worldEnvLinesManager.setActualPercentOnStart()
    opponentsManager.setActualPercentOnStart()

    this.cameraInPostIntroState = true

    cameraManager.setState(CameraStates.discipline)
    cameraManager.changeBaseRenderSettings(undefined, undefined, gameConfig.cameraConfig.fov)

    startPhaseStateManager.postIntroUiState()

    if (modes.isTutorial() && disciplinePhasesManager.attempt === 1) return
    this.launchSystem()

  }

  /**
   * Launch system
   */
  public launchSystem(): void {

    if (!modes.isTrainingMode() && !modes.isTutorial() && !modes.isTournament()) gamePhaseState().showRecords = true

    startPhaseStateManager.enableInputs(true)
    player.playerNotesManager.setVisibilityOfAll(true)
    const showFirstLine = true
    const showSecondLine = false

    startPhaseStateManager.resetTextMessageFinishedEmits(showFirstLine, showSecondLine)
    textMessageState().$patch({
      showFirstLine,
      showSecondLine,
      firstLineText: 'kayak-start-texts',
      firstLineTextType: 0,
      showMessage: true,
      showType: 3
    })

    if (!modes.isTutorial()) audioManager.fadeOutAudio(AudioNames.audienceHyped2, 1000)

    console.log('READY')
    gsap.to({}, {
      duration: 1,
      onComplete: () => {

        audioManager.play(AudioNames.readySet)

      }
    })

    this.storeState()

    worldEnv.setVisibilityFinishWall(true)
    worldEnv.resetStartlineBlockers()

    this.startTween = gsap.to({}, {

      onComplete: this.stateSet,
      callbackScope: this,
      duration: startConfig.timesForNextStep.toSet

    })

  }

  /**
   * Stav startu - set
   */
  private stateSet(): void {

    console.log('SET')

    // nastavime stav hracom, aby isli aj animacie
    disciplinePhasesManager.phaseStart.startable = true
    startPhaseStateManager.enableInputs()
    opponentsManager.setStateToAll(PlayerStates.set, startConfig.maxRandomDelaySet)

    const showFirstLine = true
    const showSecondLine = false

    startPhaseStateManager.resetTextMessageFinishedEmits(showFirstLine, showSecondLine)
    textMessageState().$patch({
      showFirstLine,
      showSecondLine,
      firstLineText: 'kayak-start-texts',
      firstLineTextType: 1,
      showMessage: true,
      showType: 3
    })

    startDebugPhaseState().isActive = true

    this.startTween = gsap.to({}, {

      onComplete: this.stateShot,
      callbackScope: this,
      duration: startConfig.timesForNextStep.toStart,
      onUpdate: () => {

        if (this.startTween) {

          startDebugPhaseState().markPosition = this.startTween.progress() * 100

        }


      }

    })

  }

  /**
   * Stav startu - vystrel
   */
  private stateShot(): void {

    this.started = true
    timeManager.setActive(TimesTypes.game, true)
    uiState().showTimeKeeper = true

    worldEnv.startlineBlockersActive = true

    startPhaseStateManager.showStartMessageInUI(
      {
        message: 'Start',
        color: 0,
        messageType: 2
      },
      2
    )
    opponentsManager.start()
    console.log('START SHOT')
    this.showShine = true
    audioManager.play(AudioNames.startSignal)

    // resetujeme framey, aby sme vedeli, ci nebol predcasny start
    this.framesInPhase = 0
    startPhaseStateManager.enableInputs()
    audioHelper.setTimerToChangeAudienceAfterStart()

    gsap.to({}, {
      onComplete: () => {

        this.showShine = false
        startDebugPhaseState().isActive = false
        startPhaseState().showShine = this.showShine

      },
      duration: 0.2
    })

  }

  /**
   * Zacatie hybania sa hraca
   */
  private startMovingPlayer(): void {

    startMessageState().showStartArrow = false
    this.startable = false

    this.wasFalseStart = this.framesInPhase < 3 || !this.started
    console.log(`hrac odstartoval vo frame ${ this.framesInPhase}, falseStart ${this.wasFalseStart}`)

    gamePhaseState().showBar = false
    actionButtonState().isStart = false

    if (this.wasFalseStart) {

      player.playerNotesManager.setVisibilityOfAll(false)
      this.makeFalseStart()
      return

    }
    const showPosition = !modes.isTutorial() && !modes.isTrainingMode()
    lapPositionState().showPosition = showPosition
    player.playerNotesManager.setVisibilityOfAll(true)
    this.correctStartSide = this.startSide === player.athleteAnimationManager.animationSide
    if (this.framesInPhase <= 10 && this.correctStartSide) {

      tutorialObjectives.passObjective(TutorialObjectiveIds.start as string)

    } else {

      tutorialObjectives.failObjective(TutorialObjectiveIds.start as string)

    }
    this.finishPhase()
    // this.ended = true

  }

  /**
   * Ziskanie kvality startu v treningu
   * @param wrongSide - wrong side
   */
  public trainingStartQuality(wrongSide: boolean) {

    if (!modes.isTrainingMode() || this.wasFalseStart) return
    const { startQualities } = trainingConfig

    let delta = this.framesInPhase
    // Na prvy pokus ak sa pokazi sa to ignoruje
    if (disciplinePhasesManager.attempt === 1 && (delta < 0 || !this.started)) return
    if (delta < 1 || !this.started) delta = 0
    if (delta > 15) delta = 15
    let value = startQualities[delta] / 100
    if (wrongSide) {

      value = Math.ceil(value * 10000 / 2) / 10000

    }
    trainingTasks.started = true
    trainingTasks.saveTaskValue(Tasks.startQuality, value)

  }

  /**
   * Inputy pri starte
   * @param side - Ktora strana bola stlacena
   */
  public handleInputs(side?: Sides): void {

    if (this.ended) return
    // odstartovanie
    if (this.startable) {

      if (side !== undefined) this.startSide = side
      this.startMovingPlayer()

    }

    // skip intra
    if (this.skippable && this.framesInPhase > 10) {

      console.log('skippped')
      this.skippable = false
      this.skipped = true
      cameraManager.skipTween()
      this.framesInPhase = 0

    }

    // skip intra druheho
    if (this.skippableSecond && this.framesInPhase > 10) {

      this.skippableSecond = false
      this.skippedSecond = true
      cameraManager.skipTween()
      this.framesInPhase = 0

    }

    // skip false startu
    if (this.skippableFalseStart) {

      this.skipFalseStart()

    }

  }

  /**
   * Update kazdy frame
   */
  public update(): void {

    if (!this.ended) this.framesInPhase++

    const { isEnabled, startFrames } = gameConfig.autoMove
    if (this.started && isEnabled && this.framesInPhase >= startFrames && !this.wasFalseStart) {

      // auto odstartovanie
      console.log(`auto start po ${this.framesInPhase} frameoch`)
      this.startSide = gameSettingsState().isLeft ? Sides.RIGHT : Sides.LEFT
      this.startMovingPlayer()

    }

    this.storeState()

  }

  /**
   * Vykonanie false startu
   */
  private makeFalseStart(): void {

    this.startTween?.kill()

    worldEnv.startlineBlockersActive = true

    if (!this.started) {

      // dame prvy vystrel
      audioManager.play(AudioNames.falseStart)
      audioHelper.setTimerToChangeAudienceAfterStart()
      console.log('start shot false start - first')

    }

    // hraci by sa mali rozbehnut
    player.start(this.startSide)
    opponentsManager.start()

    startPhaseStateManager.showStartMessageInUI(
      {
        message: 'falseStart',
        color: 2,
        messageType: 3
      },
      2
    )
    startPhaseStateManager.disableStartMessageInUI(
      {
        message: '',
        color: 0,
        messageType: 0
      },
      1
    )

    gsap.to({}, {
      duration: 1,
      onComplete: () => {

        // hraci zacnu spomalovat
        player.falseStartSlowDown()
        opponentsManager.falseStartSlowDown()
        // druhy vystrel
        if (this.started) audioManager.play(AudioNames.startSignal)
        console.log('false start UI + second shot + zaciatok spomalovania')
        tutorialFlow.eventActionTrigger(TutorialEventType.failedStart)
        inputsState().isVisible = false

        // v tutoriali nechceme, aby skipoval
        if (modes.isTutorial()) return

        const { attempt } = disciplinePhasesManager
        const audio = attempt > 1 ? AudioNames.commentatorSecondFalseStart : AudioNames.commentatorFalseStart
        audioManager.play(audio)

        gsap.to({}, {
          duration: 0.5,
          onComplete: () => {

            // moznost skipnut
            this.skippableFalseStart = true

          }
        })

        this.falseStartFinishTween = gsap.to({}, {
          duration: 3,
          onComplete: () => {

            // koniec false startu
            this.finishFalseStart()

          }
        })

      }
    })

  }

  /**
   * Skipnutie false startu
   */
  public skipFalseStart(): void {

    this.falseStartFinishTween?.kill()
    this.skippableFalseStart = false

    this.finishFalseStart()

  }

  /**
   * Create black overlay
   */
  public createBlackOverlay(): void {

    blackOverlayState().showBlackScreen = true

  }

  /**
   * Remove black overlay
   */
  public removeBlackOverlay(): void {

    blackOverlayState().showBlackScreen = false

  }

  /**
   * Ukoncenie false startu
   */
  public finishFalseStart(): void {

    // cierna obrazovka a s nou reset veci
    this.createBlackOverlay()
    this.ended = true
    console.log('finishFalseStart', tutorialManager.getActualSectionId())
    gsap.to({}, {
      onComplete: () => {

        console.warn('restart')
        disciplinePhasesManager.resetAttempt()
        tutorialFlow.reset()

      },
      duration: 0.5
    })

  }

  /**
   * Ukoncene fazy
   */
  public finishPhase(): void {

    if (!this.ended) {

      console.warn('start phase ended')
      this.showStartMessage()

      this.ended = true
      this.callbackEnd()
      player.start(this.startSide)
      tutorialFlow.eventActionTrigger(TutorialEventType.goodStart)

      this.storeState()

      startPhaseStateManager.finishPhaseUiState()
      // startPhaseStateManager.disableInputs()

    }

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

  /**
   * UI update
   */
  private storeState(): void {

    startPhaseState().$patch({
      showName: this.showName,
      showPhase: !this.ended,
      attempt: corePhasesManager.disciplineActualAttempt,
      showShine: this.showShine
    })

  }

  /**
   * zobrazime startovu spravu
   */
  private showStartMessage(): void {

    if (this.wasFalseStart) return
    const message = this.getStartingMessage()
    startPhaseStateManager.showStartMessageInUI(message, 1)

    this.startingMessageTween = gsap.to({}, {
      duration: this.STARTING_MESSAGE_DURATION,
      onComplete: () => {

        startPhaseStateManager.disableStartMessageInUI(
          {
            message: '',
            color: 0,
            messageType: 0
          },
          1
        )
        startPhaseStateManager.disableStartMessageInUI(
          {
            message: '',
            color: 0,
            messageType: 0
          },
          2
        )

      }
    })

  }

  /**
   * ziskame startovu spravu
   */
  private getStartingMessage(): DisplayMessage {

    const message = {
      message: 'slowStart',
      color: 2
    }

    let audio = AudioNames.commentatorStartPoor
    const playAudio = !audioManager.isAudioGroupPlaying(AudioGroups.commentators)

    // ak dal hrac nespravnu stranu, tak davame rovno najhorsiu hlasku
    if (!this.correctStartSide) {

      if (playAudio) audioManager.play(audio)
      return message

    }

    const { perfect, excellent, good } = startConfig.startMessageFrames
    const random = Math.round(Math.random()) === 0

    if (this.framesInPhase <= perfect) {

      message.message = 'perfectStart'
      message.color = 0
      audio = random ? AudioNames.commentatorStartPerfect : AudioNames.commentatorStartPerfect2

    } else if (this.framesInPhase <= excellent) {

      message.message = 'excellentStart'
      message.color = 0
      audio = random ? AudioNames.commentatorStartPerfect : AudioNames.commentatorStartPerfect2

    } else if (this.framesInPhase <= good) {

      message.message = 'goodStart'
      message.color = 1
      audio = AudioNames.commentatorStartGood

    }

    if (playAudio) audioManager.play(audio)

    return message

  }

  /**
   * Po vystartovani prveho bezca
   */
  public manageFirstStarted(): void {

    if (this.firstStarted) return

    this.firstStarted = true

  }

  /**
   * reset fazy
   */
  private reset(): void {

    inputsState().disabled = true
    this.skippable = true
    this.skippableSecond = false
    this.skippableFalseStart = false
    this.startable = false
    this.showName = false
    this.skipped = false
    this.skippedSecond = false
    this.ended = false
    this.clickedPower = 0
    this.started = false
    this.framesInPhase = 0
    this.showShine = false
    this.startTween = undefined
    this.falseStartFinishTween = undefined
    this.startingMessageTween = undefined
    this.cameraInPostIntroState = false
    this.firstStarted = false
    this.storeState()
    startPhaseStateManager.disableStartMessageInUI(
      {
        message: '',
        color: 0,
        messageType: 0
      },
      1
    )
    startPhaseStateManager.disableStartMessageInUI(
      {
        message: '',
        color: 0,
        messageType: 0
      },
      2
    )

  }

}
