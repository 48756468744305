import type { CSS2DObject } from '@powerplay/core-minigames'

/** Mena vsetkych modelov, ktore sa pouzivaju v minihre */
export enum ModelsNames {

  athlete = 'athlete',
  env = 'env',
  envDynamic = 'envDynamic',
  trackLines = 'trackLines',
  skybox = 'SkyboxDay',
  cameraBoxes = 'cameraBoxes',
  leadIndicators = 'leadIndicators',
  flags = 'flags',
  waterEffects = 'WaterEffects',
  notes = 'notes',

}

/** Mena vsetkych textur, ktore sa pouzivaju v minihre */
export enum TexturesNames {

  athleteRacePrefix = 'athlete_race_',
  athleteRaceWhiteMan = 'male/athlete_race_white',
  athleteRaceMulattoMan = 'male/athlete_race_mulatto',
  athleteRaceBlackMan = 'male/athlete_race_black',
  athleteRaceWhiteWoman = 'female/athlete_race_white',
  athleteRaceMulattoWoman = 'female/athlete_race_mulatto',
  athleteRaceBlackWoman = 'female/athlete_race_black',
  athleteClothes = 'athlete_clothes',
  athleteClothesOpponent1 = 'athlete_clothes_opponent_1_v',
  athleteClothesOpponent2 = 'athlete_clothes_opponent_2_v',
  athleteClothesOpponent3 = 'athlete_clothes_opponent_3_v',
  athleteClothesOpponent4 = 'athlete_clothes_opponent_4_v',
  athleteClothesOpponent5 = 'athlete_clothes_opponent_5_v',
  athleteClothesOpponent6 = 'athlete_clothes_opponent_6_v',
  athleteClothesOpponent7 = 'athlete_clothes_opponent_7_v',
  boat = 'boat',
  boatOpponent1 = 'boat_opponent_1_v',
  boatOpponent2 = 'boat_opponent_2_v',
  boatOpponent3 = 'boat_opponent_3_v',
  boatOpponent4 = 'boat_opponent_4_v',
  boatOpponent5 = 'boat_opponent_5_v',
  boatOpponent6 = 'boat_opponent_6_v',
  boatOpponent7 = 'boat_opponent_7_v',
  athleteHair = 'athleteHair',
  audience = 'audience512v',
  audienceStand = 'AudienceStanding512',
  colorGradient = 'ColorGradientAds2048v',
  concrete = 'CleanConcrete512',
  glassPanel = 'GlassPanel512',
  grass = 'GrassUncut512',
  woodFloor = 'WoodFloor1024',
  ground = 'GroundTexture512',
  lights = 'Lights512',
  metal = 'MetalDetail512',
  panel = 'Panel512',
  seats = 'Seats512v',
  stairs = 'Stairs512',
  trees = 'Trees1024v',
  plastic = 'PlasticDetail512',
  texts = 'Texts1024v',
  lattice = 'Lattice512',
  screens = 'Screens1024v',
  flagsImages = 'FlagsImages2048',
  flagGradient = 'FlagGradient256',
  gradient = 'Gradient64',
  numbers = 'NumbersTemp512',
  envLightmap = 'EnvLightmap2048v',
  skybox = 'Skybox1024',
  skyboxMountain = 'SkyboxMountain1024',
  skyboxClouds = 'SkyboxClouds1024',
  skyboxBuilding1 = 'SkyboxBuildings1_512',
  skyboxBuilding2 = 'SkyboxBuildings2_512',
  voronoiWater = 'VoronoiWater512v',
  wake = 'Wake1024v'

}

/** Mena vsetkych materialov, ktore sa pouzivaju v minihre */
export enum MaterialsNames {

  athlete = 'athlete',
  athleteOpponentPrefix = 'athlete_opponent_',
  athleteOpponent1 = 'athlete_opponent_1',
  athleteOpponent2 = 'athlete_opponent_2',
  athleteOpponent3 = 'athlete_opponent_3',
  athleteOpponent4 = 'athlete_opponent_4',
  athleteOpponent5 = 'athlete_opponent_5',
  athleteOpponent6 = 'athlete_opponent_6',
  athleteOpponent7 = 'athlete_opponent_7',
  boat = 'boat',
  boatPrefix = 'boat_opponent_',
  boatOpponent1 = 'boat_opponent_1',
  boatOpponent2 = 'boat_opponent_2',
  boatOpponent3 = 'boat_opponent_3',
  boatOpponent4 = 'boat_opponent_4',
  boatOpponent5 = 'boat_opponent_5',
  boatOpponent6 = 'boat_opponent_6',
  boatOpponent7 = 'boat_opponent_7',
  athleteHair = 'athleteHair',
  athleteHairOpponent1 = 'athleteHairOpponent1',
  athleteHairOpponent2 = 'athleteHairOpponent2',
  athleteHairOpponent3 = 'athleteHairOpponent3',
  athleteHairOpponent4 = 'athleteHairOpponent4',
  athleteHairOpponent5 = 'athleteHairOpponent5',
  athleteHairOpponent6 = 'athleteHairOpponent6',
  athleteHairOpponent7 = 'athleteHairOpponent7',
  athleteHairOpponentPrefix = 'athleteHairOpponent',
  audience = 'audience',
  audienceStand = 'audienceStand',
  colorGradient = 'colorGradient',
  concrete = 'concrete',
  glassPanel = 'glassPanel',
  woodFloor = 'woodFloor',
  grass = 'grass',
  plastic = 'plastic',
  ground = 'ground',
  screens = 'screens',
  lights = 'lights',
  metal = 'metal',
  panel = 'panel',
  trees = 'trees',
  seats = 'seats',
  stairs = 'stairs',
  lattice = 'lattice',
  numbers = 'numbers',
  lightsGradient = 'lightGradient',
  flags = 'flags',
  flagsGradient = 'FlagsGradient',
  onlyVertexColors = 'onlyVertexColors',
  leadIndicator = 'leadIndicator',
  missing = 'missing',
  water = 'water',
  numbersWithoutLM = 'numbersWithoutLM',
  colorGradientWithoutVC = 'colorGradientWithoutVC',
  onlyVertexColorsWithoutLM = 'onlyVertexColorsWithoutLM',
  latticeWithoutLM = 'latticeWithoutLM',
  panelWithoutLM = 'panelWithoutLM',
  skybox = 'Skybox',
  skyboxMontain = 'skyboxMontain',
  skyboxClouds = 'skyboxClouds',
  skyboxBuilding1 = 'skyboxBuilding1',
  skyboxBuilding2 = 'skyboxBuilding2',
  noteOuter = 'noteOuter',
  noteInner = 'noteInner',

}

/** Mena vsetkych animacii hraca */
export enum PlayerAnimationsNames {
  balance1 = 'balance 1',
  balance2 = 'balance 2',
  finishL = 'finish L',
  finishR = 'finish R',
  happy1 = 'happy 1',
  happy2 = 'happy 2',
  happy3 = 'happy 3',
  happy4 = 'happy 4',
  paddlingL = 'paddling L',
  paddlingR = 'paddling R',
  prestart = 'prestart',
  // zmenene to je kvoli tomu, lebo chceme zacinat inak ako bolo povodne planovane
  readyR = 'ready L',
  readyL = 'ready R',
  sad1 = 'sad 1',
  sad2 = 'sad 2',
  toEmotionL = 'to emotion L',
  toEmotionR = 'to emotion R'
}

/**
 * Specialne data z init requestu
 */
export interface SpecialDataFromInit {

  nothing: unknown

}

/** Farby pre lidra */
export enum LeaderColors {

  green = 0,
  lightBlue = 1

}

/** Farby pre "noty" */
export enum NoteColors {

  default = 'default',
  perfect = 'perfect',
  excellent = 'excellent',
  good = 'good',
  poor = 'poor',
  white = 'white'

}

export interface LabelQualities {

  [key: string]: CSS2DObject
}

/** Rozne eventy */
export enum CustomGameEvents {

  playerFinished = 'playerFinished'

}
